import "../../assets/projects/Projects.css"

import Projects from "./Projects";

function ProjectsPage() {
    return(
        <div className="sectionsContainers">
            {/* <div className="projectsPageTitle">
                Proyectos
            </div> */}
            <Projects/>
        </div>
    )
}

export default ProjectsPage;