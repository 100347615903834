import Categories from "./Categories";
import Description from "./Description";





function HomePage() {
    return (
        <div className='sectionsContainers'>
            <Description/>
            <Categories/>
            
      </div>
    )
}


export default HomePage;