



import ContactPlatforms from "./ContactPlatforms"


function ContactPage() {
    return(
        <div className="sectionsContainers">
            <ContactPlatforms/>
        </div>
    )
}

export default ContactPage